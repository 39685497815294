<template>
  <div>
    <a-button type="primary" :disabled="disabled" :loading="loading" @click="pushHandle">
      {{ btnText }}
    </a-button>

    <a-modal :title="$t('下推')" v-model="nextVisible" @cancel="nextVisible = false">
      <a-form-model>
        <a-form-model-item :label="$t('单据类型')" required>
          <a-select v-model="nextType" style="width: 100%">
            <a-select-option :value="item.code" v-for="item in nextTypeList" :key="item.code">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button @click="nextVisible = false">{{ $t('取消') }}</a-button>
        <a-button type="primary" @click="pushNextHandle">{{ $t('确定') }}</a-button>
      </template>
    </a-modal>

    <a-modal
      :title="$t('单据转换报告')"
      v-model="resultVisible"
      width="1000px"
      :keyboard="false"
      :maskClosable="false"
    >
      <CommonTable
        :columns="resultColumns"
        :showSetting="false"
        :dataSource="resultList"
        :showPagination="false"
      >
        <template v-slot:no="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:status="{ record }">
          <span class="text-success" v-if="record.success">
            {{ $t('成功') }}
          </span>
          <span class="text-error" v-else>
            {{ $t('失败') }}
          </span>
        </template>
      </CommonTable>
      <template slot="footer">
        <a-button @click="resultVisible = false">{{ $t('关闭') }}</a-button>
        <a-button type="primary" @click="goNextHandle" v-if="pushTableList.length > 0">
          {{ $t('下推') }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { subtract } from '@/common/number'
export default {
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    docType: {
      type: String,
      default: 'PayableOrder', //默认是应付单PayableOrder下推 采购申请单PaymentRequest 预付单DepositPayment
    },
    pageType: {
      type: String, //页面类型 list detail
    },
  },
  data() {
    return {
      loading: false,
      nextVisible: false,
      nextType: 'paymentRequest',
      resultVisible: false,
      resultList: [],
      showPushBtn: false,
      pushTableList: [],
      nextTypeList: [],
    }
  },
  computed: {
    btnText() {
      let text = this.$t('下推')
      if (['DepositPayment', 'PaymentRequest'].includes(this.docType)) {
        text = this.$t('下推付款单')
      }
      return text
    },
    resultColumns() {
      return [
        {
          title: this.$t('源单数据'),
          dataIndex: 'orderSn',
        },
        {
          title: this.$t('下推状态'),
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: this.$t('消息'),
          dataIndex: 'desc',
        },
      ]
    },
    isFromPayToReceipt() {
      return this.docType === 'PayableOrder' && this.nextType === 'receiptNote'
    },
    isFromReceivableToPayment() {
      return this.docType === 'ReceivableOrder' && this.nextType === 'paymentNote'
    },
  },
  methods: {
    pushHandle() {
      //判断selectedRows里面的供应商是不是同一个
      let supplierList = this.selectedRows.map((item) => item.supplierCode)
      let supplierSet = new Set(supplierList)
      if (supplierSet.size > 1) {
        this.$message.warning(this.$t('请选择同一个供应商的订单'))
        return
      }
      //预付单和应付申请单直接下推付款单
      if (['DepositPayment', 'PaymentRequest'].includes(this.docType)) {
        this.nextType = 'paymentNote'
        this.pushNextHandle()
        return
      }

      this.nextTypeList = this.getNextTypeList()
      this.$nextTick(() => {
        this.nextVisible = true
      })
    },
    getNextTypeList() {
      if (this.docType === 'ReceivableOrder') {
        //应收
        let list = [
          {
            code: 'receiptNote',
            name: this.$t('收款单'),
          },
          {
            code: 'receivableOrderAdjust',
            name: this.$t('应收调整单'),
          },
          {
            code: 'paymentNote',
            name: this.$t('付款单'),
          },
        ]
        if (this.pageType === 'list') {
          list = list.filter((item) => item.code !== 'receivableOrderAdjust')
        }
        this.nextType = 'receiptNote'
        if (this.selectedRows.length === 1) {
          if (this.selectedRows[0].receivableAmount < 0) {
            //销售退货单只能下推付款单
            this.nextType = 'paymentNote'
            list = [
              {
                code: 'paymentNote',
                name: this.$t('付款单'),
              },
            ]
          }
        }
        return list
      }
      this.nextType = 'paymentRequest'
      let list = [
        {
          code: 'paymentRequest',
          name: this.$t('付款申请单'),
        },
        {
          code: 'paymentNote',
          name: this.$t('付款单'),
        },
        {
          code: 'payableOrderAdjust',
          name: this.$t('应付调整单'),
        },
        {
          code: 'receiptNote',
          name: this.$t('收款单'),
        },
      ]
      if (this.pageType === 'list') {
        list = list.filter((item) => item.code !== 'payableOrderAdjust')
      }
      if (this.selectedRows.length === 1) {
        if (this.selectedRows[0].payableAmount < 0) {
          //采购退货应付单只能下推收款单
          this.nextType = 'receiptNote'
          list = [
            {
              code: 'receiptNote',
              name: this.$t('收款单'),
            },
          ]
        }
      }
      return list
    },
    pushNextHandle() {
      if (this.nextType === 'payableOrderAdjust' || this.nextType === 'receivableOrderAdjust') {
        this.$emit('pushAdjust', this.selectedRows)
        return
      }
      let pushList = this.selectedRows
      this.getTableData(pushList)
      this.nextVisible = false
    },
    getTableData(list) {
      let result = [],
        data = []
      let name = this.$t('付款')
      list.forEach((item) => {
        //应收单
        if (this.docType === 'ReceivableOrder') {
          name = this.$t('收款')
          if (!['WAIT_WRITTEN_OFF', 'PART_WRITTEN_OFF'].includes(item.status)) {
            result.push({
              orderSn: item.orderSn,
              success: false,
              desc: this.$t('不符合条件: 请选择待核销、部分核销单据'),
            })
            return
          }
        }
        //应付单
        if (this.docType === 'PayableOrder') {
          if (!['WAIT_WRITTEN_OFF', 'PART_WRITTEN_OFF'].includes(item.status)) {
            result.push({
              orderSn: item.orderSn,
              success: false,
              desc: this.$t('不符合条件: 请选择待核销、部分核销单据'),
            })
            return
          }
        }
        //付款申请单、预付单
        if (['DepositPayment', 'PaymentRequest'].includes(this.docType)) {
          if (!['WAIT_PAY', 'PART_PAID'].includes(item.status)) {
            result.push({
              orderSn: item.orderSn,
              success: false,
              desc: this.$t('不符合条件: 请选择待付款、部分付款单据'),
            })
            return
          }
        }
        let planResult = [],
          hasError = false
        let tableList =
          item.planList || item.detailList || item.detail_list || item.receiptPlanList || []
        tableList.forEach((elem, index) => {
          let unLockAmount = 0
          if (this.docType === 'ReceivableOrder') {
            unLockAmount = subtract(elem.receivableAmount, elem.lockAmount || 0)
          } else if (this.docType === 'PaymentRequest') {
            unLockAmount = subtract(elem.approvedAmount, elem.lockAmount || 0)
          } else {
            unLockAmount = subtract(elem.payableAmount, elem.lockAmount || 0)
          }
          // console.log('unLockAmount: ', unLockAmount)
          if (elem.status && elem.status !== 'NORMAL') {
            planResult.push({
              orderSn: item.orderSn + this.$t(`${name}计划第X行`, { X: index + 1 }),
              success: false,
              desc: this.$t(`不符合条件: ${name}计划状态需为未冻结`),
            })
            hasError = true
            return
          }
          if (this.isFromPayToReceipt && unLockAmount >= 0) {
            planResult.push({
              orderSn: item.orderSn + this.$t(`${name}计划第X行`, { X: index + 1 }),
              success: false,
              desc: this.$t(`不符合条件: 下推收款单时${name}计划未锁定金额需小于0`),
            })
            hasError = true
            return
          }
          if (this.isFromReceivableToPayment && unLockAmount >= 0) {
            planResult.push({
              orderSn: item.orderSn + this.$t(`${name}计划第X行`, { X: index + 1 }),
              success: false,
              desc: this.$t(`不符合条件: 下推付款单时${name}计划未锁定金额需小于0`),
            })
            hasError = true
            return
          }
          if (!this.isFromPayToReceipt && !this.isFromReceivableToPayment && unLockAmount <= 0) {
            planResult.push({
              orderSn: item.orderSn + this.$t(`${name}计划第X行`, { X: index + 1 }),
              success: false,
              desc: this.$t(`不符合条件: ${name}计划未锁定金额需大于0`),
            })
            hasError = true
            return
          }
          const obj = {
            dueDate: moment().format('YYYY-MM-DD'),
            referDocDetailId: elem.id,
            referDocId: item.id,
            referDocSn: item.orderSn,
            referDocType: this.docType,
            sourceDocDetailId: elem.sourceDocDetailId || elem.id,
            sourceDocId: elem.sourceDocId || item.id,
            sourceDocSn: elem.sourceDocSn || item.orderSn,
            sourceDocType: elem.sourceDocType || this.docType,
            settlementCurrency: elem.settlementCurrency || item.settlementCurrency,
            discountAmount: '',
            remark: '',
          }
          if (
            (this.nextType !== 'receiptNote' && name === this.$t('付款')) ||
            this.nextType === 'paymentNote'
          ) {
            let paymentPurpose = elem.isPrepay ? 'PREPAYMENT' : 'PURCHASE_PAYMENT'
            if (this.isFromReceivableToPayment) {
              unLockAmount *= -1
              paymentPurpose = elem.isPrepay ? 'PREPAYMENT_REFUND' : 'SALE_REFUND'
            }
            Object.assign(obj, {
              payableAmount: unLockAmount,
              applyAmount: unLockAmount,
              expectedPaymentAmount: unLockAmount,
              paymentAmount: unLockAmount,
              paymentPurpose,
            })
          } else {
            if (this.isFromPayToReceipt) {
              unLockAmount *= -1
            }
            Object.assign(obj, {
              receivableAmount: unLockAmount,
              receiptAmount: unLockAmount,
            })
          }
          data.push(obj)
          planResult.push({
            orderSn: item.orderSn + this.$t(`${name}计划第X行`, { X: index + 1 }),
            success: true,
            desc: this.$t('符合条件'),
          })
        })
        if (hasError) {
          result = [...result, ...planResult]
        } else {
          result.push({
            orderSn: item.orderSn,
            success: true,
            desc: this.$t('符合条件'),
          })
        }
      })
      this.resultList = result
      this.pushTableList = data

      if (result.length === 1 && result[0].success) {
        this.goNextHandle()
        return
      }

      this.resultVisible = true
    },
    goNextHandle() {
      localStorage.setItem('referDocType', this.docType)
      const pushFormData = {
        ...this.selectedRows[0],
        docType: this.docType,
      }
      localStorage.setItem('pushFormData', JSON.stringify(pushFormData))
      localStorage.setItem('pushTableList', JSON.stringify(this.pushTableList))
      this.$router.push({
        name: this.nextType + 'Detail',
      })
      this.resultVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.text-note {
  color: #999;
  font-size: 12px;
}
</style>
